<template>
    <section id="home" class="home-section">
        <div class="home-content">
            <img src="../../assets/profile.jpeg" alt="Rahul Ojha" class="profile-photo"> <!-- Add your photo here -->
            <h1>Welcome to My Portfolio</h1>
            <TypewriterComponent />
            <p>
                Hello and thank you for visiting my corner of the internet! I'm <span class="name">Rahul</span>, A passionate Developer with a
                love for Problem Solving.<br>
                Here, you'll get a glimpse into my journey, projects, and the skills I bring to the table.
            </p>
            <br>
            <a href="#about" class="cta-button smooth-link">About Me</a>
            <a href="https://nextcloud-zo0ww4sokwgo80w80sg80k8c.rahulojha.in/s/WqGze96QCeTFHZp" target="_blank" class="cta-button">Resume</a>
            <div class="social-links">
                <a href="https://github.com/rahul-ojha-07" target="_blank"><i class="fab fa-github"></i>GitHub</a>
                <a href="https://www.linkedin.com/in/rahulojha07" target="_blank"><i
                        class="fab fa-linkedin-in"></i>LinkedIn</a>
                <a href="https://x.com/rahul_ojha_07" target="_blank"><i class="fab fa-x-twitter"></i> Twitter</a>
                <a href="https://www.facebook.com/rahul.ojha.07" target="_blank"><i
                        class="fab fa-facebook"></i>Facebook</a>
                <a href="https://medium.com/@rahul-ojha-07" target="_blank"><i class="fab fa-medium"></i> Medium</a>
                <a href="https://stackoverflow.com/users/8400785/rahul-ojha-07" target="_blank"><i
                        class="fab fa-stack-overflow"></i> Stack Overflow</a>
                <a href="https://www.instagram.com/rahul_ojha_07/" target="_blank"><i class="fab fa-instagram"></i>
                    Instagram</a>
            </div>
        </div>
    </section>
</template>

<script src="./HomeSection.js"></script>

<style src="./HomeSection.scss" lang="scss"></style>